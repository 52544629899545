import { Section, Button, Center, Columns, Column, Box, Right } from "../../../../src/components/bulma";
import * as React from 'react';
export default {
  Section,
  Button,
  Center,
  Columns,
  Column,
  Box,
  Right,
  React
};