import React from 'react'
import { Link } from 'gatsby'

const Columns = ({ vcenter, children }) => {
  if (vcenter) return <div className="columns is-vcentered">{children}</div>
  return <div className="columns">{children}</div>
}

const Column = ({ width, children }) => <div className={`column ${width ? `is-${width}` : ''}`}>{children}</div>

const Section = ({ children }) => <div className="section">{children}</div>

const Box = ({ children }) => <div className="box">{children}</div>

const Block = ({ children }) => <div className="block">{children}</div>

const Button = ({ children, link }) => {
  if (link)
    return (
      <Link to={link}>
        <button className="button" type="button">
          {children}
        </button>
      </Link>
    )

  return (
    <button className="button" type="button">
      {children}
    </button>
  )
}

const Center = ({ children }) => <div className="has-text-centered">{children}</div>

const Right = ({ children }) => <div className="has-text-right">{children}</div>

const Primary = ({ children }) => <div className="has-text-primary">{children}</div>

export { Block, Box, Button, Center, Columns, Column, Primary, Right, Section }
