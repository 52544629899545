import React, { useState } from 'react'
import { navigate } from 'gatsby'

const Contact = () => {
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const submitForm = ev => {
    ev.preventDefault()
    setLoadingSubmit(true)
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        navigate('/contact/thanks/')
      } else {
        setLoadingSubmit(false)
        console.log('error')
      }
    }
    xhr.send(data)
  }

  return (
    // <form onSubmit={submitForm} action="https://formspree.io/f/mjvpjnnn" method="POST">
    <form>
      <div className="field">
        <div className="label">Your name</div>
        <div className="control">
          <input type="text" className="input" name="name" />
        </div>
      </div>
      <div className="field">
        <div className="label">Email Address</div>
        <div className="control">
          <input type="text" className="input" name="email" />
        </div>
      </div>
      <div className="field">
        <div className="label">Message</div>
        <div className="control">
          <textarea className="textarea" name="message" />
        </div>
      </div>
      <div className="field">
        <div className="control">
          <button type="submit" className={`button is-primary ${loadingSubmit ? 'is-loading' : ''}`}>
            Submit
          </button>
        </div>
      </div>
    </form>
  )
}

export default Contact
